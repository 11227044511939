<template>
  <v-container fluid>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <headerLayaut subtitle="Comercios que manejan eventos">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedCities"
            :items="cities"
            @change="getBusinesses"
            prepend-icon="fa-city"
            label="Seleccionar ciudad"
            item-text="name"
            item-value=".key"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            style="border-radius: 10px"
            rounded
            multiple
            :allow-overflow="false"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class="flex-end">
          <v-btn
            :block="isMobile"
            color="primary"
            style="border-radius: 10px"
            :disabled="!selectedCities.length"
            class="ma-2 white--text"
            @click="showAddBusiness()"
            v-if="actionsRoles.includes(user.type)"
            :loading="loading"
          >
            <v-icon right dark class="mr-3"> fas fa-plus </v-icon>
            Crear nuevo comercio
          </v-btn>
        </v-col>
      </v-row>
    </headerLayaut>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="getHeader"
          :items="filterBusinessesByCity"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          sort-by="createdAt"
          :sort-desc="true"
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.imagen`]="{ item }">
            <v-avatar
              class="mt-3"
              v-if="item.picture != 'assets/imgs/avatar-default.svg'"
            >
              <v-img
                style="object-fit: cover"
                :src="item.logo.original"
                :alt="item.name"
                :lazy-src="item.logo.loading"
              >
              </v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.shortName`]="{ item }">
            <div @click="editBusiness(item)">
              {{ item.shortName }}
            </div>
          </template>

          <template v-slot:[`item.businessId`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item['.key'])"
                >
                  {{ item[".key"] || "0000-0000" }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.phone)"
                >
                  {{ item.phone || "0000-0000" }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.isFather`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isFather: item.isFather },
                  item['.key'],
                  item,
                  'isFather'
                )
              "
              v-model="item.isFather"
            ></v-switch>
          </template>

          <template v-slot:[`item.featured`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { featured: item.featured },
                  item['.key'],
                  item,
                  'featured'
                )
              "
              v-model="item.featured"
            ></v-switch>
          </template>

          <template v-slot:[`item.ochoActive`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { ochoActive: item.ochoActive },
                  item['.key'],
                  item,
                  'ochoActive'
                )
              "
              v-model="item.ochoActive"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isComingSoon`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isComingSoon: item.isComingSoon },
                  item['.key'],
                  item,
                  'isComingSoon'
                )
              "
              v-model="item.isComingSoon"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item,
                  'active'
                )
              "
              v-model="item.active"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.closed`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { closed: item.closed },
                  item['.key'],
                  item,
                  'closed'
                )
              "
              v-model="item.closed"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isForceClosed`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isForceClosed: item.isForceClosed },
                  item['.key'],
                  item,
                  'isForceClosed'
                )
              "
              v-model="item.isForceClosed"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isCongested`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isCongested: item.isCongested },
                  item['.key'],
                  item,
                  'isCongested'
                )
              "
              v-model="item.isCongested"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.isTestMode`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isTestMode: item.isTestMode },
                  item['.key'],
                  item,
                  'isTestMode'
                )
              "
              v-model="item.isTestMode"
            >
            </v-switch>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
       
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    dark
                    style="border-radius: 10px"
                    small
                    @click="editBusiness(item)"
                    :elevation="0"
                    color="info"
                  >
                    <v-icon small dark>fa-solid fa-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver eventos del comercio {{ item.name }}</span>
              </v-tooltip>
              <v-tooltip left v-if="['sudo','viveSudo','viveLesserAdmin','viveOperations','viveBusiness'].includes(user.type)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    dark
                    style="border-radius: 10px"
                    small
                    @click="editBusinessInfo(item)"
                    :elevation="0"
                    color="warning"
                  >
                    <v-icon small dark>fa-solid fa-pen</v-icon>
                  </v-btn>
                </template>
                <span>Editar comercio {{ item.name }}</span>
              </v-tooltip>
              <!-- <v-btn
                @click="clone(item)"
                v-if="actionsRoles.includes(user.type)"
                class="ml-3 my-2"
                small
                color="primary"
              >
                Clonar
              </v-btn> -->

              <!-- <v-btn
                :disabled="!item.isFather == true"
                @click="addChildren(item)"
                class="ml-3 my-2"
                v-if="actionsRoles.includes(user.type)"
                small
                color="primary"
              >
                Asignar comercios
              </v-btn> -->
              <!-- <v-btn
                @click="deleteBusiness(item)"
                v-if="actionsRoles.includes(user.type)"
                small
                class="ml-3 my-2 white--text"
                color="red darken-4"
                >Eliminar</v-btn
              > -->
       
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              >
              </v-skeleton-loader>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modalNewBusiness"
      persistent
      max-width="1000px"
      scrollable
    >
      <new-business
        :operationType="operationType"
        :businessOriginal="businessToClone"
        :key="randomKey"
        @cancel="modalNewBusiness = false"
        @success="handleCreate"
      >
      </new-business>
    </v-dialog>

    <v-dialog v-model="modalFeaturedBusinesses" max-width="800px">
      <businesses-states
        :key="`states-${randomKey}`"
        :businesses="businesses"
        @cancel="modalFeaturedBusinesses = false"
        @success="handleClone"
      >
      </businesses-states>
    </v-dialog>

    <v-dialog
      v-model="modalAddChildren"
      ref="childrenDialog"
      handleChildren
      max-width="70%"
    >
      <add-children-businesses
        :businesses="businesses"
        :businessFather="businessFather"
        :key="`children-${randomKey}`"
        @cancel="modalAddChildren = false"
        @success="handleChildren"
      >
      </add-children-businesses>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.shortName }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            :loading="saving"
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditComer" scrollable max-width="800">
      <formBusiness
        v-if="dialogEditComer"
        :dataEdit="businessDB"
        @cancel="dialogEditComer = !dialogEditComer"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState, mapActions } from "vuex";
import newBusiness from "./new-business";
import addChildrenBusinesses from "./add-children-businesses";
import businessesStates from "./businesses-states";
import mobile from "is-mobile";
import formBusiness from "@/views/sudo/businesses/new-business.vue";
import ledgerMixin from "@/mixins/ledgerMixin";
import headerLayaut from "@/components/header-layaut.vue";
export default {
  components: {
    Lottie,
    newBusiness,
    addChildrenBusinesses,
    businessesStates,
    headerLayaut,
    formBusiness,
  },
  mixins: [ledgerMixin],
  data() {
    return {
      randomKey: 0,
      dialogEditComer: false,
      selectBusiness: true,
      saving: false,
      businessDB: null,
      itemSelected: null,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      businesses: [],
      cities: [],
      selectedCity: "",
      selectedCities: [],
      snackbarText: "",
      loading: true,
      snackbar: false,
      modalDelete: false,
      itemToDelete: null,
      actionsRoles: ["admin", "sudo", "AC", "EFCM"],
      headers: [
        {
          value: "imagen",
        },
        {
          text: "ID del comercio",
          value: "businessId",
          class: "nowrap",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "shortName",
          width: "200px",
          class: "nowrap",
        },

        {
          text: "Teléfono",
          value: "phone",
          width: "100px",
          class: "nowrap",
        },

        // {
        //   text: "Es agrupador",
        //   value: "isFather",
        //   class: "nowrap",
        //   roles: ["sudo"],
        // },
        // {
        //   text: "Activo por ocho",
        //   value: "ochoActive",
        //   class: "nowrap",
        //   roles: ["sudo"],
        // },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
          roles: ["sudo"],
        },
        {
          text: "Próximamente",
          value: "isComingSoon",
          class: "nowrap",
          roles: ["sudo"],
        },
        {
          text: "Creado",
          value: "createdAt",
          width: "180px",
          class: "nowrap",
          roles: ["sudo"],
        },
        { text: " ", value: "options" },
      ],
      modalNewBusiness: false,
      modalCloneBusiness: false,
      businessToClone: {},
      modalAddChildren: false,
      businessFather: {},
      operationType: "",
      modalFeaturedBusinesses: false,
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },
  computed: {
    ...mapState(["user"]),
    getHeader() {
      return this.headers.filter(
        (item) =>
          !item.roles || (item.roles && item.roles.includes(this.user.type))
      );
    },
    filterBusinessesByCity() {
      if (this.selectedCity) {
        return this.businesses.filter((business) =>
          this.selectedCity.includes(business.city)
        );
      } else {
        return this.businesses;
      }
    },
    isMobile() {
      return mobile();
    },
  },

  // watch: {
  //   // businesses() {
  //   //   this.businesses.forEach((business) => {
  //   //     let searchCity = this.cities.find((i) => i[".key"] == business.city);
  //   //     business.cityName = searchCity ? searchCity.name : "";
  //   //   });
  //   // },
  // },
  methods: {
    ...mapActions([
      "addBusiness",
      "addCorporative",
      "addEventSelection",
      "Alert_",
    ]),
    editBusinessInfo(item) {
      this.businessDB = item;
      this.dialogEditComer = true;
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    handleClone() {
      this.modalCloneBusiness = false;
      // this.getBusinesses();
      this.snackbarText = "Comercio clonado exitosamente.";
      this.snackbar = true;
    },
    handleChildren() {
      this.modalAddChildren = false;
      this.snackbarText = "Comercios asociados exitosamente.";
      this.snackbar = true;
    },
    showAddBusiness() {
      this.modalNewBusiness = true;
      this.randomKey = Math.random();
      this.operationType = "new";
    },
    clone(item) {
      this.randomKey = Math.random();
      this.businessToClone = item;
      this.operationType = "clone";
      this.modalNewBusiness = true;
    },
    addChildren(item) {
      this.randomKey = Math.random();
      this.businessFather = item;
      this.modalAddChildren = true;
    },
    handleCreate() {
      this.modalNewBusiness = false;
      // this.getBusinesses();
      this.snackbarText = "Comercio creado exitosamente.";
      this.snackbar = true;
    },
    deleteBusiness(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },
    confirmDelete() {
      this.saving = true;
      let data = {
        deleted: true,
        deletedAt: new Date(),
        deletedBy: this.user[".key"],
      };
      db.collection("businesses")
        .doc(this.itemToDelete[".key"])
        .update(data)
        .then((ref) => {
          this.saving = false;
          this.businesses = this.businesses.filter(
            (item) => item[".key"] != this.itemToDelete[".key"]
          );
          this.snackbarText = "Comercio eliminado correctamente.";
          this.snackbar = true;
          this.modalDelete = false;
          this.itemToDelete = null;
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    // editBusiness(item) {
    //   this.$root.$children[0].businessListener(item);
    //   this.addBusiness(item);
    //   this.addCorporative(null);
    //   this.snackbarText = "Comercio agregado al menú";
    //   this.snackbar = true;

    //   setTimeout(() => {
    //     document.getElementById("businessMenu").click();

    //     this.scroll();
    //   }, 500);
    // },
    editBusiness(item) {
      this.addBusiness(null);
      this.addEventSelection(null);
      setTimeout(() => {
        this.addBusiness(item);

        this.Alert_({
          text: "Comercio seleccionado y activo en panel izquierdo",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "#00cdbc",
        });
        this.$router.push("/events");
      }, 1000);
    },
    scroll() {
      setTimeout(() => {
        let ele = document.getElementsByClassName(
          "v-navigation-drawer__content"
        );

        ele[0].scroll({ top: ele[0].scrollHeight, behavior: "smooth" });
      }, 500);
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        })
        .catch((err) =>console.error("Async: Could not copy text: ", err));
    },
    async getBusinesses(e) {
      this.loading = true;
      if (e && e.length) {
        await this.$binding(
          "businesses",
          db
            .collection("businesses")
            .where("manageEvents", "==", true)
            .where("cities", "array-contains-any", e)
            .where("deleted", "==", false)
        );
      } else {
        this.businesses = [];
      }

      this.loading = false;
    },
    updateCascadeBusiness(businessId, business) {
      db.collection(`cities/${business.cities[0]}/sections`)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let sectionId = doc.id;
            let section = doc.data();

            let items = section.target.filter((doc) => {
              return doc.business == businessId;
            });

            items.forEach((elementUpdate) => {
              elementUpdate.isBusinessActive = business.active;
              elementUpdate.closed = business.closed;
              elementUpdate.isForceClosed = business.isForceClosed;
              elementUpdate.isCongested = business.isCongested;
              elementUpdate.isComingSoon = business.isComingSoon;
            });

            db.collection(`cities/${business.cities[0]}/sections`)
              .doc(sectionId)
              .update({
                target: section.target,
              })
              .catch((error) => {
                throw error;
              });
          });
        })
        .catch(console.error);
    },
    switchControlChanged(data, businessId, business, field) {
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && businessId) {
        this.saving = true;
        db.collection("businesses")
          .doc(businessId)
          .update(data)
          .then(() => {
            if (
              business.isLobbySection == true ||
              business.isLobbySection == false
            ) {
              this.updateCascadeBusiness(businessId, business);
            }
            this.saving = false;
            this.snackbarText =
              "Información del comercio actualizada correctamente.";
            this.snackbar = true;

            this.createLedger({
              source_id: businessId,
              source_type: "businesses",
              currentValue: data,
              prevValue: prevData,
            });
          })
          .catch((err) => {
           console.log(err);
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            business[field] = !business[field];
          });
      }
    },
    showFeaturedBusinesses() {
      this.modalFeaturedBusinesses = true;
    },
  },
  async mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "comercio";
    this.$store.state.search = "";

    await db
      .collection("cities")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let city = item.data();
          city[".key"] = item.id;
          this.cities.push(city);
        });
      });
    // await this.getBusinesses();

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.options-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
