<template>
  <v-card>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay style="z-index: 9999 !important" class="loading-center" :value="saving">
      <lottie :options="defaultOptions" :height="300" :width="400" v-on:animCreated="handleAnimation" />
    </v-overlay>
    <v-card-title class="grid-close">
      <span class="headline">
        {{ operationType == "new" ? "Nuevo" : "Editar" }} comercio</span>
      <v-btn style="right: 10px; top: 10px" icon color="primary" fab absolute @click="$emit('cancel')">
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text class="text--primary pt-6">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-img v-on="on" :src="imgSrcBanner || business.cover.original" aspect-ratio="1"
            class="grey lighten-2 cover-up" max-width="100%" max-height="250" @click="selectImage('banner')">
            <v-row class="fill-height ma-0" align="end" justify="center">
              <v-chip style="width: 20%" class="mb-5 img-tag"> Cover </v-chip>
            </v-row>
          </v-img>
        </template>
        <span>Cambiar imágen de cover</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-img v-on="on" :src="imgSrcBrand || business.logo.original" aspect-ratio="1" class="grey lighten-2 logo-up"
            max-width="150" max-height="150" @click="selectImage('brand')">
            <v-row class="fill-height ma-0" align="end" justify="center">
              <v-chip class="mb-1 img-tag">Logo</v-chip>
            </v-row>
          </v-img>
        </template>
        <span>Cambiar imágen de logo</span>
      </v-tooltip>
      <v-card class="text-left pa-1 ma-1 flex-grow-1" :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'white'"
        elevation="0" style="border-radius: 17px !important; overflow: hidden">
      </v-card>
      <v-row class="pa-0 ma-0 pt-2 mt-2">
        <v-col cols="12" md="6">
          <v-text-field style="border-radius: 10px" filled label="Nombre del comercio" type="text"
            placeholder="Persona a contactar en el negocio" v-model="business.shortName" rounded dense></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field style="border-radius: 10px" filled label="RTN" type="text"
            placeholder="Número de persona de contacto" v-model="business.tin" rounded dense></v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0 pt-2 mt-2">
        <v-col cols="12" md="6">
          <v-text-field style="border-radius: 10px" filled label="Persona de contacto" type="text"
            placeholder="Persona a contactar en el negocio" v-model="business.contactName" rounded dense></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field style="border-radius: 10px" filled label="Teléfono" type="text"
            placeholder="Número de persona de contacto" v-model="business.phone" rounded dense></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 pt-2 mt-2">
        <v-col cols="12" md="6">
          <v-text-field style="border-radius: 10px" filled label="Correo del negocio" type="text"
            placeholder="Correo del negocio" v-model="business.emailBusiness"     :rules="[ rules.email]" rounded dense></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field style="border-radius: 10px" filled label="Teléfono del negocio" type="text"
            placeholder="Número del negocio" v-model="business.phoneBusiness" rounded dense></v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0 pt-4 mt-4">
        <v-col cols="12" md="6">
          <v-textarea label="Dirección" v-model="business.zone" style="border-radius: 10px" filled rounded
            placeholder="Dirección exacta del negocio" class="control-input" rows="5"></v-textarea>
        </v-col>

        <v-col cols="12" md="6">
          <v-textarea label="Descripción" style="border-radius: 10px" filled placeholder="Descripción corta del negocio"
            v-model="business.description" class="control-input" rows="5" rounded></v-textarea>
        </v-col>
      </v-row>

      <!-- <v-row class="pa-0 ma-0 pt-4 mt-4">
                    <v-col cols="12" sm="12" md="6">
                      <h2>Título sección envío gratis</h2>
                      <input
                        class="control-input"
                        type="text"
                        v-model="business.freeShippingSectionTitle"
                      />
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                      <h2>Subtítulo sección envío gratis</h2>
                      <input
                        class="control-input"
                        type="text"
                        v-model="business.freeShippingSectionSubtitle"
                      />
                    </v-col>
                  </v-row> -->

      <v-row class="pa-0 ma-0 pt-4 mt-4">
        <v-col cols="12" sm="12" md="12">
          <v-text-field label="Eslogan" filled rounded style="border-radius: 15px"
            placeholder="Ingrese el eslogan de la empresa" v-model="business.slogan"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-autocomplete v-model="business.cities" :items="cities" item-value="value" label="Ciudad" multiple
            item-text="text" filled rounded style="min-height: 43px !important; border-radius: 10px !important"
            class="mt-5">
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip v-bind="attrs" :input-value="selected" @click="select">
                <strong>{{ item.text }}</strong>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-autocomplete v-model="business.oViveCategories" :items="getCategories" multiple filled
            style="border-radius: 15px" label="Categorias" :disabled="business.cities.length > 0 ? false : true"
            item-value="value" item-text="fullName" chips rounded
            messages="Las categorias no seleccionables ya pertenecen a un evento " class="mt-5">
          </v-autocomplete>
        </v-col>

        <!-- <v-col cols="12">
                      <h2>Tags</h2>
                      <v-autocomplete
                        v-model="business.tags"
                        :items="getTags"
                        multiple
                        filled
                        chips
                        rounded
                        item-value="value"
                        item-text="text"
                        style="min-height: 45px"
                        class="mt-5"
                      >
                      </v-autocomplete>
                    </v-col> -->

        <!-- <v-col cols="12">
                      <h2>Keywords</h2>
                      <v-combobox
                        v-model="business.keywords"
                        :search-input.sync="comboboxSearch"
                        hide-selected
                        label="Palabras clave para la busqueda"
                        multiple
                        rounded
                        filled
                        chips
                        deletable-chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Enter <kbd>enter</kbd> pagar agregar
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col> -->
      </v-row>

      <v-row class="pa-0 ma-0 pt-4 mt-4" v-if="!dataEdit">
        <v-col cols="12" md="6">
          <v-card-subtitle>Latitud</v-card-subtitle>
          <v-text-field type="number" class="v-control control-input-number" @keypress="isNumber($event)"
            placeholder="Latitud" filled style="border-radius: 15px" rounded v-model="address[0]" />
        </v-col>

        <v-col cols="12" md="6">
          <v-card-subtitle>Longitud</v-card-subtitle>
          <v-text-field type="number" class="control-input control-input-number" @keypress="isNumber($event)"
            placeholder="Longitud" rounded style="border-radius: 15px" filled v-model="address[1]" />
        </v-col>
        <v-col v-if="categories.length > 0" cols="12" xs="12" sm="12" md="12">
          <l-map @update:center="centerUpdated" class="fill-height" style="height: 50vh; border-radius: 10px"
            :zoom="zoom" :center="address">
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker @update:lat-lng="positionUpdated" :draggable="true" :lat-lng="address" :icon="getImage">
              <l-tooltip>
                Mueva el marcador a la ubicación del comercio
              </l-tooltip>
            </l-marker>
            <v-geosearch :options="geosearchOptions"></v-geosearch>
          </l-map>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 pt-4 mt-4" v-if="isSudo">
        <v-col cols="12" md="6">
          <v-text-field class="control-input control-input-number" @keypress="isNumber($event, business.discount)"
            type="number" filled style="border-radius: 15px" rounded label="Comisión" placeholder="Comisión"
            v-model.number="business.discount"></v-text-field>
        </v-col>
        <!-- 
                    <v-col cols="12" md="6">
                      <h2>Tipo de delivery</h2>

                      <v-autocomplete
                        v-model="business.deliveryType"
                        :items="deliveryType"
                        placeholder="Seleccione tipo de delivery"
                        filled
                        item-text="name"
                        item-value="value"
                        rounded
                        class="mt-5"
                      />
                    </v-col> -->

        <!-- <v-col cols="12" md="6">
                      <h2>Tipo de flota</h2>

                      <v-autocomplete
                        v-model="business.fleetType"
                        :items="fleetType"
                        placeholder="Seleccione tipo de flota"
                        filled
                        item-text="name"
                        item-value="value"
                        rounded
                        class="mt-5"
                      />
                    </v-col> -->

        <!-- <v-col cols="12" md="6">
                      <h2>Método de carga</h2>
                      <input
                        class="control-input"
                        type="text"
                        placeholder="Ingrese el método de carga"
                        v-model="business.methodCharge"
                      />
                    </v-col> -->

        <!-- <v-col cols="12" md="6">
                      <h2>Método de validación</h2>
                      <input
                        class="control-input"
                        type="text"
                        placeholder="Método de validación"
                        v-model="business.methodValidate"
                      />
                    </v-col> -->

        <!-- <v-col cols="12" md="6">
                      <h2>Venta mínima</h2>
                      <input
                        class="control-input control-input-number"
                        @keypress="isNumber($event, business.minimumSale)"
                        type="number"
                        placeholder="Venta mínima"
                        v-model.number="business.minimumSale"
                      />
                    </v-col> -->

        <!-- <v-col class="mt-5" cols="12" md="6">
                      <h2>Tipo de pantalla</h2>
                      <v-autocomplete
                        v-model="business.type"
                        :items="types"
                        placeholder="Seleccione tipo de pantalla"
                        filled
                        item-text="name"
                        item-value="value"
                        rounded
                        class="mt-5"
                      />
                    </v-col> -->

        <!-- <v-col class="mt-5" cols="12" md="6">
                      <h2>Zona Horario</h2>
                      <v-autocomplete
                        v-model="business.timeZone"
                        :items="timeZone"
                        placeholder="Seleccione la zona horaria"
                        filled
                        rounded
                        class="mt-5"
                      />
                    </v-col> -->
      </v-row>
      <v-row v-if="extraFees.length > 0" class="pa-0 ma-0 pt-4 mt-4">
        <!-- <v-row v-for="(fee, index) of extraFees" :key="index">
                      <v-col cols="12" md="3">
                        <h2>Tipo de recargo</h2>
                        <v-autocomplete
                          v-model="fee.extraFeeIsFixed"
                          :items="extraFeeIsFixed"
                          placeholder="Seleccione tipo de recargo"
                          filled
                          rounded
                          class="mt-5"
                        />
                      </v-col>

                      <v-col cols="12" xs="4" sm="4" md="3">
                        <h2>Nombre del recargo</h2>
                        <input
                          class="control-input"
                          type="text"
                          placeholder="Nombre del recargo"
                          v-model="fee.extraFeeName"
                        />
                      </v-col>

                      <v-col cols="12" md="3">
                        <h2>Valor del recargo</h2>
                        <input
                          class="control-input control-input-number"
                          @keypress="isNumber($event, fee.extraFeeSum)"
                          type="number"
                          placeholder="Valor del recargo"
                          v-model.number="fee.extraFeeSum"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-row>
                          <v-col class="mt-5" xs="6" sm="6" md="6">
                            <v-switch
                              v-model="fee.active"
                              label="Activa"
                            ></v-switch>
                          </v-col>
                          <v-col xs="6" sm="6" md="6">
                            <v-btn
                              @click="removeFee(index)"
                              small
                              color="red darken-4 ml-2"
                              class="white--text mt-10"
                              >Eliminar</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row> -->

        <!-- <v-col cols="12" sm="12" md="12">
                      <a @click="addFees()">Agregar tarifa</a>
                    </v-col> -->

        <!-- <v-col class="mt-5" cols="12">
                      <h3>Configuración de KM</h3>
                      <v-divider class="mb-5"></v-divider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <h2>Costo por Km recorrido</h2>
                      <input
                        class="control-input control-input-number"
                        @keypress="isNumber($event)"
                        type="number"
                        placeholder="Ingrese el costo por Km recorrido"
                        v-model.number="business.kmConfiguration.dayCostKm"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <h2>Costo por recoger</h2>
                      <input
                        class="control-input control-input-number"
                        @keypress="isNumber($event)"
                        type="number"
                        placeholder="Ingrese el Costo por recoger"
                        v-model.number="business.kmConfiguration.dropoffDay"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <h2>Costo por entregar</h2>
                      <input
                        class="control-input control-input-number"
                        @keypress="isNumber($event)"
                        type="number"
                        placeholder="Ingrese el costo por entregar"
                        v-model.number="business.kmConfiguration.pickupDay"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <h2>Cargo extra por KM</h2>
                      <input
                        class="control-input control-input-number"
                        @keypress="isNumber($event)"
                        type="number"
                        placeholder="Ingrese el cargo extra por KM"
                        v-model.number="business.kmConfiguration.extraKmCharge"
                      />
                    </v-col>

                    <v-col class="mt-5" cols="12">
                      <v-divider></v-divider>
                    </v-col> -->

        <!-- <v-col cols="12" md="4">
                      <h2>Precio</h2>
                      <v-autocomplete
                        v-model="business.priceRange"
                        :items="priceRange"
                        placeholder="Seleccione el rango de precio"
                        filled
                        rounded
                        class="mt-5"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <h2>Tiempo promedio</h2>
                      <input
                        class="control-input control-input-number"
                        type="number"
                        @keypress="isNumber($event)"
                        placeholder="Ingrese el tiempo promedio"
                        v-model.number="business.processTime"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <h2>Costo por empaque</h2>
                      <input
                        class="control-input control-input-number"
                        type="number"
                        @keypress="isNumber($event)"
                        placeholder="Ingrese el costo por empaque"
                        v-model.number="business.takeOutCost"
                      />
                    </v-col> -->

        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-switch v-model="business.active" label="Activo"></v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-switch v-model="business.ochoActive" label="Activo por ocho"></v-switch>
        </v-col>
      </v-row>
      <input class="upload" ref="input" type="file" name="image" accept="image/*" @change="setImage" />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions justify-end class="flex-end">
      <v-btn v-if="operationType == 'new'" @click="newBusiness" width="200px" :loading="save" color="primary"
        dark>Guardar</v-btn>
      <v-btn v-else @click="editComer" width="200px" color="primary" dark>Editar comercio</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import { LMap, LTileLayer, LIcon, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import axios from "axios";

export default {
  name: "new-business",
  props: ["operationType", "dataEdit"],
  components: {
    lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    VGeosearch,
    LTooltip,
  },
  data() {
    return {
      save: false,
      saving: false,
      allEvent: [],
      comboboxSearch: null,
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 27,
      rules: {
        required: (value) => !!value || "Obligatorio",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      center: [47.41322, -1.219482],
      priceRange: [1, 2, 3, 4],
      business: {
        active: false,
        adults: false,
        bankAccounts: [
          {
            bank: "",
            currency: "",
            name: "",
            number: "",
            type: "",
          },
        ],
        beforeTax: true,
        city: "",
        cities: [],
        closed: true,
        contactName: null,
        cover: {
          loading: "",
          original: "",
        },
        createdAt: "",
        createdBy: "",
        currentPercentage: 0.15,
        deleted: false,
        deletedAt: "",
        deletedBy: "",
        deliveryType: "regular",
        description: "",
        discount: 0.15,
        dispatchTime: 0,
        elasticType: "up",
        expectedWeeklySales: 500,
        featured: false,
        freeShippingMinimum: 0,
        geoAddress: [],
        isComingSoon: true,
        isForceClosed: false,
        isOpen: true,
        isCongested: false,
        isTestMode: false,
        kmApply: true,

        logo: {
          loading: "",
          original: "",
        },
        longName: "",
        manageEvents: false,
        manageInventory: false,
        minimumSale: 0,
        ochoActive: true,
        oViveCategories: [],
        ochoCategoryKm: false,
        ochoVerified: true,
        openTable: false,
        openTablePolicy: "",
        overhead: 0,
        paymentMethods: ["transfer", "deposit", "cash"],
        percentageLimit: 16,
        percentageStep: 1,
        phone: "",
        pickupActive: true,
        pins: [1234],
        priceRange: 4,
        processTime: 20,
        rating: 0,
        reservations: false,
        schedule: {
          monday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
          tuesday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
          wednesday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
          thursday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
          friday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
          saturday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
          sunday: [
            {
              opensAt: "00:00:00",
              closesAt: "00:01:00",
            },
          ],
        },
        shortName: "",
        slogan: "",
        freeShippingSectionTitle: "",
        freeShippingSectionSubtitle: "",
        standAlone: false,
        switchLayout: true,
        tagLine: "",
        tags: [],
        keywords: [],
        takeOutCost: 0,
        taxed: true,
        tin: "",
        webName: "",
        zone: "",
      },
      snackbar: false,
      snackbarText: null,
      modalUploadImage: false,
      loading: true,
      animationSpeed: 1.1,
      categories: [],
      timeZone: [
        "-1200",
        "-1100",
        "-1000",
        "-900",
        "-800",
        "-700",
        "-600",
        "-500",
        "-400",
        "-300",
        "-200",
        "-100",
        "0",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "1000",
        "1100",
        "1200",
      ],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      imageType: null,
      suscriptionBusiness: null,
      allTags: [],
      imgSrc: null,
      imgSrcBanner: "",
      imgSrcBrand: "",
      imageFile: null,
      cities: [],
      address: ["14.0985252", "-87.1789062"],
      imageBannerFile: null,
      imageBrandFile: null,
      deliveryType: [
        { name: "Regular", value: "regular" },
        { name: "Recogida", value: "pickUp" },
        { name: "Recogida regular", value: "regularPickUp" },
        { name: "Ninguno", value: "none" },
      ],
      fleetType: [
        { name: "Ocho", value: "ocho" },
        { name: "Mixto", value: "mixed" },
        { name: "Propia", value: "own" },
      ],
      types: [
        { name: "Airtime", value: "airTime" },
        { name: "ATM", value: "atm" },
        { name: "Wallet", value: "wallet" },
        { name: "Store", value: "store" },
      ],
      extraFeeIsFixed: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      extraFees: [
        {
          extraFeeIsFixed: "",
          extraFeeName: "",
          extraFeeSum: "",
          active: false,
        },
      ],
    };
  },
  watch: {
    "business.oViveCategories"() {
      if (this.business && this.business.tags) {
        this.business.tags = this.business.tags.filter((item) =>
          this.getTags.includes(item)
        );
      }
    },
    "business.cities"() {
      let filteredCategories = [];
      this.categories.forEach((category) => {
        if (
          this.business.oViveCategories.includes(category.value) &&
          this.business.cities.includes(category.city)
        ) {
          filteredCategories.push(category.value);
        }
      });
      this.business.oViveCategories = filteredCategories;
    },
  },
  computed: {
    ...mapState(["selectedEvent", "user"]),
    isSudo() {
      return this.user.type == "sudo" ? true : false;
    },
    getCategories() {
      let categories = this.categories.filter(
        (item) =>
          this.business.cities && this.business.cities.includes(item.city)
      );
      var categoriesEvent = [];
      this.allEvent.forEach((x) => {
        if (x.categories) {
          x.categories.forEach((e) => {
            if (!categoriesEvent.includes(e)) {
              categoriesEvent.push(e);
            }
          });
        }
      });

      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities.find((city) => city.value === category.city);
          newCategory.fullName = city ? `${city.text} - ${category.text}` : "";
          newCategory.disabled = categoriesEvent.includes(newCategory.value)
            ? true
            : false;
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
    getUserToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    getTags() {
      let tags = [];
      this.business.oViveCategories.forEach((cat) => {
        let result = this.categories.find((item) => item.value == cat);
        tags = tags.concat(result ? result.tags : []);
      });

      return tags.sort(this.compareArray);
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    validateBusiness() {
      let controls = [
        {
          name: "Nombre del comercio",
          control: "shortName",
        },
        {
          name: "Dirección",
          control: "zone",
        },
        {
          name: "Descripción",
          control: "description",
        },
        {
          name: "Categorias",
          control: "oViveCategories",
        },
        {
          name: "Ciudad",
          control: "cities",
        },
      ];

      for (let index = 0; index < controls.length; index++) {
        if (!this.business[controls[index].control]) {
          this.handleError(controls[index].name);
          this.save = false;
          this.saving = false;
          return false;
        }
      }

      if (!this.address[0] || !this.address[0]) {
        this.snackbarText = `Ubicación inválida`;
        this.save = false;
        this.saving = false;
        this.snackbar = true;
        return false;
      }

      if (this.business.oViveCategories.length == 0) {
        this.snackbarText = `Debe seleccionar una categoría válida.`;
        this.snackbar = true;
        return false;
      }
      return true;
    },

    getImage() {
      return L.icon({
        iconUrl: require("../../../assets/avatar-default.png"),
        shadowUrl: require("@/assets/map-marker.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },
  methods: {
    handleError(control) {
      this.snackbarText = `El campo ${control} es obligatorio`;
      this.snackbar = true;
    },
    positionUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },
    removeFee(index) {
      this.extraFees = this.extraFees.filter((item, i) => i != index);

      if (this.extraFees.length == 0) {
        this.extraFees = [
          {
            extraFeeIsFixed: "",
            extraFeeName: "",
            extraFeeSum: "",
            active: false,
          },
        ];
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    centerUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    setImage(e) {
      this.imageFile = e.target.files[0];

      if (this.imageType == "banner") this.imageBannerFile = e.target.files[0];
      else if (this.imageType == "brand")
        this.imageBrandFile = e.target.files[0];

      if (e.target.files[0].type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (this.imageType == "banner")
            this.imgSrcBanner = event.target.result;
          else this.imgSrcBrand = event.target.result;
          this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    successUpload: function () {
      this.modalUploadImage = false;
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Imágen actualizada";
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    selectImage: function (imageType) {
      this.imageType = imageType;
      this.$refs.input.click();
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    getGeoPoint(token, businessId) {
      return new Promise((resolve, reject) => {
        let data = {
          lat: this.address[0],
          lng: this.address[1],
          businessId: businessId,
        };

        var httpBusinessesUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpBusinessesUpdateGeoPoint");
        httpBusinessesUpdateGeoPoint(data)
          .then((result) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async newBusiness() {
      this.save = true;
      this.saving = true;
      let token = await this.getSectionToken;

      if (this.validateBusiness) {
        this.saving = true;
        this.business.longName = this.business.shortName;
        this.business.createdBy = this.$store.state.user[".key"];
        this.business.createdAt = new Date();
        this.business.extraFees = this.extraFees;
        this.business.manageEvents = true;
        db.collection("businesses")
          .add(this.business)
          .then(async (ref) => {
            await this.getGeoPoint(token, ref.id);
            await this.updatePictures(ref.id);
            this.saving = false;
            this.save = false;
            this.$emit("success");
          });
      }
    },
    async editComer() {
      this.save = true;
      this.saving = true;
      let token = await this.getSectionToken;

      if (this.validateBusiness) {
        this.saving = true;
        this.business.longName = this.business.shortName;
        this.business.createdBy = this.$store.state.user[".key"];
        this.business.createdAt = new Date();
        delete this.business[`.key`];
        this.business.extraFees = this.extraFees;
        this.business.manageEvents = true;
        console.log(this.business);
        db.collection("businesses")
          .doc(this.dataEdit[`.key`])
          .update(this.business)
          .then(async (ref) => {
            if (this.imageBrandFile) {
              await this.updatePictures(this.dataEdit[`.key`]);
            }
            this.saving = false;
            this.save = false;
            this.$emit("cancel");
          });
      } else {
        this.save = false;
        this.saving = false;
        this.$emit("cancel");
      }
    },

    updatePictures(businessId) {
      return new Promise(async (resolve, reject) => {
        let bucket = `${process.env.VUE_APP_EL_OCHO_BK}-businesses`;
        if (bucket) {
          let uploadBrand = new Promise((resolve, reject) => {
            if (this.imageBrandFile) {
              fb.app()
                .storage(bucket)
                .ref()
                .child(`${businessId}/brand`)
                .put(this.imageBrandFile)
                .then((res) => {
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });
          await uploadBrand;

          let uploadBanner = new Promise((resolve, reject) => {
            if (this.imageBannerFile) {
              fb.app()
                .storage(bucket)
                .ref()
                .child(`${businessId}/banner`)
                .put(this.imageBannerFile)
                .then((res) => {
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });

          await uploadBanner;
          resolve("success");
        }
      });
    },
    compare(a, b) {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    },
    compareArray(a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    compareByFullName(a, b) {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    },
  },
  async mounted() {
    //// console.debug(this.operationType);
    let scrollPosition = document.getElementsByClassName("v-dialog")[0];
    if (scrollPosition) {
      scrollPosition.scrollTop = 0;
    }

    await db
      .collection("cities")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let city = {
            value: item.id,
            text: item.data().name,
          };
          this.cities.push(city);
        });
      });

    await db
      .collection("oViveCategories")
      .where("deleted", "==", false)
      .where("active", "==", true)
      .get()
      .then(async (response) => {
        // console.debug(response);
        response.forEach(async (item) => {
          let category = {
            value: item.id,
            text: item.data().name,
            city: item.data().city,
            tags: item.data() && item.data().tags ? item.data().tags : [],
          };

          this.categories.push(category);
        });
      });

    if (this.dataEdit) {
      this.$binding(
        "allEvent",
        db
          .collection("events")
          .where("business", "==", this.dataEdit[`.key`])
          .where("active", "==", true)
      );
      this.business = Object.assign({}, this.dataEdit);
      if (this.business.extraFees && this.business.extraFees.length > 0)
        this.extraFees = Object.assign([], this.business.extraFees);
      else this.business.extraFees = this.extraFees;

      if (!this.business.hasOwnProperty("isTestMode"))
        this.business.isTestMode = false;
    }
    //// console.debug(this.categiriesFilter);
  },
};
</script>

<style scoped>
.logo,
.cover {
  margin-top: 20px;
  cursor: pointer;
}

.save-btn {
  margin-top: 20px;
}

.add-tag {
  background-color: rgba(0, 0, 0, 0.37);
}

.add-tag-ico {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px;
}

.btn-modal-add-tags {
  justify-content: center;
}

.upload-container .logo {
  width: 10%;
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -8%;
  margin-left: 20px;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.upload-container .banner {
  border: 1px solid black;
  border-radius: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.upload {
  display: none;
}

.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}

.logo-up:hover {
  border: 2px dashed #f06f3d;
  cursor: pointer;
}

.cover-up:hover {
  border: 1px dashed #f06f3d;
  cursor: pointer;
}

.add-image {
  font-size: 30px;
  color: rgba(128, 128, 128, 0.733) !important;
  cursor: pointer;
}

.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
}

.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}

.leaflet-interactive {
  cursor: move !important;
}
</style>
